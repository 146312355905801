import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import {
  faArrowRight,
  faHeart,
  faList,
  faLock,
  faMapMarkerAlt,
  faPen,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faUser,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Nav,
  Image,
  Navbar,
  Dropdown,
  Container,
  Col,
  Row,
  Badge,
  Button,
} from "react-bootstrap";
import axios from "../axios";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Routes } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/img/logo.png";
import logoIcon from "../assets/img/wtmIcon.png";
import "./Navbar.css";
import useCart from "../Hooks/UseCart";
import AsyncSearchBar from "./AsyncSearchBar/AsyncSearchBar";
import Notifications from "./Notifications";
import useUserLocation from "../Hooks/useUserLocation";
import { loadingSet } from "../redux/loadingSlice";
import useIsLgOrSmaller from "../Hooks/useIsLgorSmaller";

const MainNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const modalRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location;
  const isPanel = pathname.includes("panel");

  const userData = useSelector((state) => state.login.userData);
  const planDetails = useSelector((state) => state.plan.planData);
  const userLocation = useSelector((state) => state.login.location);
  const { cart } = useCart();

  const fetchProdCateg = async () => {
    const res = await axios("/product/categories");
    return res.data.data.categories;
  };
  const {
    data: categories = [],
    isLoading: prodCatLoading,
    // error: catError,
  } = useQuery({
    queryKey: ["productCategories"],
    queryFn: fetchProdCateg,
  });

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const loading = useSelector((state) => state.loading.loading);

  // Close modal on clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setExpanded(false);
      }
    };
    if (expanded) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [expanded]);

  useEffect(() => {
    dispatch(loadingSet(prodCatLoading));
  }, [prodCatLoading]);

  const handleToggle = () => {
    setExpanded((prevState) => !prevState);
    setTimeout(() => {}, 0);
  };

  const handleLogout = () => {
    Cookies.remove("authToken");
    Cookies.remove("userData");
    Cookies.remove("plan_id");
    Cookies.remove("plan_data");
    axios.defaults.headers.common = {};
    history.replace(Routes.Signin.path);
    history.go(0);
  };
  useUserLocation();

  const petCategory = categories.find((cat) => cat.name.includes("Pet"));

  const renderNavLinks = () => (
    <>
      <NavLink
        // activeClassName="underline"
        className={`fontweigh-500 link-text d-block ${
          pathname.includes("storefronts") ? "underline" : ""
        } ${isLgOrSmaller ? "my-3" : ""}`}
        to={{
          pathname: `${
            Routes.Explore.path
          }/storefronts/state=${userLocation.state?.replaceAll(" ", "-")}`,
        }}
        // to={{ pathname: `${Routes.Explore.path}/Storefronts` }}
        onClick={() => setExpanded(false)}
      >
        DISPENSARIES
      </NavLink>
      {/* <NavLink
                        // activeClassName="underline"
                        className={`fontweigh-500 link-text mt-4 mx-3 mb-1 ${
                          pathname.includes("Deliveries") ? "underline" : ""
                        }`}
                        to={{
                          pathname: `${
                            Routes.Explore.path
                          }/Deliveries/state=${userLocation.state?.replaceAll(
                            " ",
                            "-"
                          )}/${userLocation.lat}/${userLocation.long}`,
                        }}
                        onClick={() => setExpanded(false)}
                      >
                        DELIVERY
                      </NavLink> */}

      <NavLink
        activeClassName="underline"
        className={`fontweigh-500 link-text d-block ${
          isLgOrSmaller ? "my-3" : ""
        }`}
        to={{
          pathname: Routes.NearbyDeals.path,
          // state: {
          //   position,
          // },
        }}
        onClick={() => setExpanded(false)}
      >
        DEALS
      </NavLink>
      <NavLink
        className="fontweigh-500 link-text d-block"
        activeClassName="underline"
        to={Routes.allBrands.path}
        onClick={() => setExpanded(false)}
      >
        BRANDS
      </NavLink>
      {/* <NavLink
                        activeClassName="underline"
                        className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                        to={{ pathname: `${Routes.Explore.path}/clinics` }}
                        onClick={() => setExpanded(false)}
                      >
                        DOCTORS
                      </NavLink> */}
      {/* <NavLink
                        activeClassName="underline"
                        className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                        to={{
                          pathname: `${
                            Routes.ProductsByCategory.path
                          }/${petCategory?.name?.replaceAll(" ", "-")}/${
                            petCategory?.id
                          }`,
                        }}
                        onClick={() => setExpanded(false)}
                      >
                        PETS
                      </NavLink> */}
      {/* <NavLink
                        activeClassName="underline"
                        className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                        to={{ pathname: Routes.blogs.path }}
                        onClick={() => setExpanded(false)}
                      >
                        BLOGS
                      </NavLink> */}
    </>
  );

  const renderUserDropdown = () => (
    <Dropdown variant="link" className="px-1">
      <Dropdown.Toggle variant="link" className="pt-1 px-0">
        <div className="media d-flex align-items-center black-color">
          {userData.profile_pic ? (
            <Image
              alt="profile pic"
              src={userData.profile_pic}
              className="user-avatar md-avatar rounded-circle"
            />
          ) : (
            <FontAwesomeIcon
              icon={faUser}
              className="user-avatar md-avatar rounded-circle bg-dark"
            />
          )}
          <div className="media-body align-items-center d-none d-lg-block ms-lg-2">
            <span className="mb-0 font-small fontweigh-500 responsive-text">
              {userData?.name?.length > 16
                ? `${userData.name.substring(0, 16)}...`
                : userData.name}
            </span>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
        {(userData.role_id === 1 ||
          (userData.role_id === 2 && planDetails.plan_id)) && (
          <Dropdown.Item as={Link} to={Routes.AdminDashboard.path}>
            <FontAwesomeIcon icon={faArrowRight} className="me-2" /> Dashboard
          </Dropdown.Item>
        )}
        <Dropdown.Item
          as={Link}
          to={Routes.MyProfile.path}
          // className="fw-bold"
        >
          <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
        </Dropdown.Item>
        {userData.role === "user" && (
        <Dropdown.Item
          as={Link}
          to={Routes.myOrders.path}
          // className="fw-bold"
        >
          <FontAwesomeIcon icon={faList} className="me-2" /> My Orders
        </Dropdown.Item>
         )}
        {userData.role === "user" && (
          <Dropdown.Item as={Link} to={Routes.myFavorites.path}>
            <FontAwesomeIcon icon={faHeart} className="me-2" /> My Favorites
          </Dropdown.Item>
        )}
        {userData.role === "user" && (
          <Dropdown.Item as={Link} to={Routes.myReviews.path}>
            <FontAwesomeIcon icon={faPen} className="me-2" /> My Reviews
          </Dropdown.Item>
        )}
        <Dropdown.Item
          // className="fw-bold"
          onClick={() => history.push(Routes.ChangePassword.path)}
        >
          <FontAwesomeIcon icon={faLock} className="me-2" /> Change Password
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" />{" "}
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
  const isLgOrSmaller = useIsLgOrSmaller();

  return (
    <>
      <Navbar
        expand="lg"
        expanded={expanded}
        className="bg-body-tertiary d-block"
        sticky="top"
        style={{
          boxShadow: "0px 0px 7px #65ac4b",
          zIndex: 2,
          background: "#fff",
        }}
      >
        {" "}
        {/* <Progress now={0} variant="success" size="xl" loading={loading} /> */}
        <Container fluid className="align-items-start justify-content-end px-3">
          {!isPanel && (
            <>
              <Col className="ml-2 d-flex align-items-end">
                <Navbar.Toggle
                  onClick={handleToggle}
                  className="me-sm-4 me-2"
                />
                <Navbar.Brand
                  className="me-md-2 me-0"
                  as={Link}
                  to={Routes.Home.path}
                >
                  <Image
                    style={{ height: "3.3rem" }}
                    fluid
                    src={isSmallScreen ? logoIcon : logo}
                    alt="logo"
                  />
                </Navbar.Brand>
              </Col>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="flex-column align-items-stretch"
                ref={modalRef}
              >
                <>
                  <Row className="align-items-center mb-2">
                    <Col className="d-lg-flex align-items-center justify-content-between">
                      {/* Text Section */}
                      <div
                        className={`d-flex align-items-center me-2 ${
                          isLgOrSmaller ? "mb-3" : ""
                        }`}
                      >
                        {userData.role !== "admin" && (
                          <Button
                            // style={{ fontSize: "0.9rem", whiteSpace: "nowrap" }}
                            variant="outline-success"
                            className="fontweigh-500 "
                            as={Link}
                            to={{
                              pathname: planDetails.plan_id
                                ? Routes.buyPlan.path
                                : userData.role === "partner"
                                ? userData.state
                                  ? Routes.buyPlan.path
                                  : Routes.MyProfile.path
                                : Routes.Signup.path,
                              state: { type: "partner" },
                            }}
                            onClick={() => setExpanded(false)}
                          >
                            <FontAwesomeIcon icon={faSignInAlt} />{" "}
                            {planDetails.plan_id
                              ? "Upgrade Business"
                              : "List Business"}
                          </Button>
                        )}
                        {expanded && (
                          <div className="ms-auto ">
                            <Button
                              variant="link"
                              className="p-0"
                              onClick={handleToggle}
                            >
                              <FontAwesomeIcon
                                className="fs-2 green-color"
                                icon={faWindowClose}
                              />
                            </Button>
                          </div>
                        )}
                      </div>
                      <>
                        {isLgOrSmaller
                          ? userLocation.state && (
                              <div className="flex-grow-1">
                                <AsyncSearchBar
                                  lat={userLocation.lat}
                                  long={userLocation.long}
                                  state={userLocation.state}
                                  setExpanded={setExpanded}
                                />
                              </div>
                            )
                          : renderNavLinks()}
                      </>
                    </Col>
                  </Row>
                  {isLgOrSmaller ? (
                    renderNavLinks()
                  ) : (
                    <Row>
                      {userLocation.state && (
                        <div className="flex-grow-1">
                          <AsyncSearchBar
                            lat={userLocation.lat}
                            long={userLocation.long}
                            state={userLocation.state}
                            setExpanded={setExpanded}
                          />
                        </div>
                      )}
                    </Row>
                  )}
                </>
              </Navbar.Collapse>
            </>
          )}
          <Col
            // md={4}
            // xs={6}
            className="d-flex justify-content-end align-items-center float-end ms-4"
          >
            {userLocation.state && (
              <div className="black-color d-flex fs-5 mx-md-2 mx-xl-3">
                <FontAwesomeIcon
                  className="fs-5 location-icon"
                  icon={faMapMarkerAlt}
                />{" "}
                <span className="fs-7">{userLocation.state}</span>
              </div>
            )}
            {userData.role ? (
              // <Nav className="align-items-center">
              <>
                {!isPanel&&userData.role ==="user"&& (
                  <Nav.Link
                    as={Link}
                    to={Routes.cart.path}
                    className="d-flex align-items-center px-1 icon-cover"
                  >
                    <FontAwesomeIcon
                      className="fontweigh-500 link-text cart-icon"
                      icon={faShoppingCart}
                      // style={{ height: "1.2em", width: "1.2em" }}
                    />
                    <Badge className="bg-green count-badge">
                      {cart?.cart_items && cart?.cart_items.length}
                    </Badge>
                  </Nav.Link>
                )}
                {isPanel && <Notifications />}
                {/* {userData.role !== "user" && <Notifications />} */}
                {renderUserDropdown()}
                {/* </Nav> */}
              </>
            ) : (
              <Nav.Link
                className="fontweigh-500 green-color fs-6"
                href={Routes.Signin.path}
              >
                LogIn
              </Nav.Link>
            )}
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

export default MainNavbar;
